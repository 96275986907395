<template>
  <div>
    <box-message-welcome
      :message="message"
      :title="
        $t('components.home.messageWelcome.subscriberCreditCardPaying.greeting')
      "
      :emoji-codes="[215]"
    />
    <div data-cy="dashboard-banner_bankslip-btn" class="banner-1-green">
      <p class="green">{{
        $t(
          "components.home.messageWelcome.subscriberCreditCardPaying.paymentMessageTitle"
        )
      }}</p> 
      {{
        $t(
          "components.home.messageWelcome.subscriberCreditCardPaying.paymentMessage"
        )
      }}
      {{ nextBillingDate }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs-ext";
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BoxMessageWelcome,
  },
  data() {
    return {
      message: "",
      nextBillingDate: "",
    };
  },
  computed: {
    ...mapGetters("store", ["store", "subscription"]),
  },
  mounted() {
    const formattedRegistrationDate = dayjs(this.store.confirmedAt).format(
      "DD/MM/YYYY"
    );
    this.message = `${this.$t(
      "components.home.messageWelcome.subscriberCreditCardPaying.message"
    )} ${formattedRegistrationDate}`;
    this.nextBillingDate = dayjs(
      this.subscription?.subscription_expiration ??
        this.subscription?.license_expiration
    ).format("DD/MM/YYYY");
  },
};
</script>

<style>
.green {
  color: green;
  font-weight: 700;
}
</style>

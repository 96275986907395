<template>
    <div>
        <box-message-welcome
            :message="
                $t('components.home.messageWelcome.SubscriberPending.message')
            "
            :title="$t('components.home.messageWelcome.notSubscriber.greeting')"
            :emoji-codes="[215]"
        />
    </div>
</template>

<script>
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        BoxMessageWelcome,
    },
    computed: {
        ...mapGetters({
            eventGtm: 'eventGtm'
        }),
        
    },
};
</script>

<style></style>

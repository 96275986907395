<template>
    <section class="container w-auto -mx-2 sm:mx-4 text-left">
        <div class="container py-0 mx-auto">
            <h1 class="mb-5 text-xl text-black">
                {{ $t("edit_profile.your_register") }}
            </h1>
            <form method="post" @submit.prevent="saveForm">
                <div class="mb-5 lg:flex lg:space-x-5">
                    <div class="box-item">
                        <h2>{{ $t("edit_profile.owner_data") }}</h2>
                        <div class="box-form">
                            <FormPerson @personData="updatePerson" />
                        </div>
                        <div class="flex flex-wrap">
                        <FormulateInput
                        type="select"
                        name="documentType"
                        :label="$t('form.document_type')"
                        v-model="getProfile.person.doc_type"
                        :options="tipoDocList"
                        :outer-class="['w-full sm:w-1/2 sm:pl-1 md:w-2/2']"
                        :error-class="['text-red-600']"
                        data-cy="profile_form-document-type"
                        @click="eventGtm('custom.editDocumentType')"
                        />
                        <FormulateInput
                            @validation="validation = $event"
                            v-mask="mask"
                            v-model="getProfile.person.cpf_cnpj"
                            type="text"
                            :label="$t('form.document_number')"
                            name="document"
                            icon="id-card"
                            inputmode="text"
                            :validation="documentValidation"
                            :validation-messages="{
                                required: $t('form.required_field'),
                                min: $t('form.input_invalid')
                            }"
                            :error-class="['text-red-600']"
                            :outer-class="['w-full sm:w-1/2 sm:pr-1 md:w-2/2 md:pl-1']"
                            data-cy="profile_form-document-number"
                            @click="eventGtm('custom.editDocument')"
                        />
                    </div>
                        <div class="box-form">
                            <FormPhone @phoneData="updatePhones" />
                        </div>
                    </div>

                    <div class="box-item">
                        <h2>{{ $t("edit_profile.profile_data") }}</h2>
                        <div class="box-form">
                            <FormStore @storeData="updateStore" />
                        </div>
                    </div>
                </div>
                    <div class="mb-5 box-item">
                        <h2>{{ $t("edit_profile.billing_address") }}</h2>
                        <div class="box-form">
                            <FormAddress
                                @addressData="updateBillingAddress"
                                address-type="billingAddress"
                            />
                        </div>
                    </div>
                <div class="text-right">
                    <button
                        class="btn-cta-blue"
                        type="submit"
                    >
                        {{ $t("edit_profile.save") }}
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import FormPerson from "../components/Form/FormPerson.vue";
import FormStore from "../components/Form/FormStore.vue";
import FormPhone from "../components/Form/FormPhone.vue";
import FormAddress from "../components/Form/FormAddress.vue";
import { mapActions, mapGetters } from "vuex";
import {STORE, STORE_MODULE, UPDATE_PROFILE } from "@/store/constants/store";
import { mask } from "vue-the-mask";
export default {
    name: "StoreProfile",
    directives: { mask },
    data() {
        return {
            person: {},
            phones: {},
            store: {},
            profile: {},
            billingAddress: {},
            validation: {},
            dataError: "",
            errorType: "",
            documents: "",
            tipoDocList: {
                CUIT:"CUIT",
                CUIL:"CUIL",
                CDI:"CDI",
                DNI:"DNI",
            },
        };
    },
    computed: {
        ...mapGetters(STORE_MODULE, ["storeProfile"]),
        ...mapGetters({
            getProfile: `${STORE_MODULE}/${STORE}`,
            eventGtm: 'eventGtm'
        }),
        mask(){
            if (this.getProfile.person.doc_type == 'DNI') return ['##.###.###']  
                else return ['##-########-#']           
        },
        documentValidation(){
            if (this.getProfile.person.doc_type == 'DNI') return "bail|required|min:10"  
                else return "bail|required|min:13" 
        }
    },
    components: {
        FormPerson,
        FormStore,
        FormPhone,
        FormAddress,
    },
    mounted() {
        if (!this.storeProfile?.account) {
            window.location = "/";
        }        
        this.profile.document = this.getProfile.document;
        this.profile.documentType = this.getProfile.documentType;
    },
    methods: {
        ...mapActions({
            updateProfile: `${STORE_MODULE}/${UPDATE_PROFILE}`,
        }),
        updateDocument(){
            this.documents = {
                document: this.getProfile.document,
                documentType: this.getProfile.documentType,
            };
            this.updateProfile(this.documents)
        },
        async saveForm() {
            if (!this.validateStoreInfos()) {
                this.$fire({
                    title: this.$t('updateProfile.validate_title'),
                    text: this.$t("updateProfile.validate_text", {
                        dataError: this.dataError,
                        errorType: this.errorType,
                    }),
                    type: "error",
                    timer: 3000,
                });
                return false;
            }
            const data = {
                person: this.person,
                document: this.profile.document,
                DocumentType: this.profile.documentType,
                phones: this.phones,
                store: this.store,
                billingAddress: this.billingAddress,
            };
            try {
                this.updateDocument()
                await this.updateProfile(data);
                this.$fire({
                    title: this.$t('updateProfile.success_title'),
                    text: this.$t('updateProfile.success_text'),
                    type: "success",
                    timer: 3000,
                    onClose: () => {
                        window.location = "/";
                    },
                });
            } catch (error) {
                this.$fire({
                    title: this.$t('updateProfile.error_title'),
                    text: this.$t('updateProfile.error_title'),
                    type: "error",
                    timer: 3000,
                });
            }
        },
        updatePerson(data) {
            this.person = data;
        },
        updatePhones(data) {
            this.phones = data;
        },
        updateStore(data) {
            this.store = data;
        },
        updateBillingAddress(data) {
            this.billingAddress = data;
        },
        validateStoreInfos() {
           
            if (!this.store?.company_name) {
                this.dataError = this.$t('form.company_name');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.store?.store_name) {
                this.dataError = this.$t('form.alias');
                this.errorType = this.$t('form.required');
                return false;
            }

            if (!this.billingAddress?.street) {
                this.dataError = this.$t('form.address');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.billingAddress?.zipcode) {
                this.dataError = this.$t('form.postal_code');
                this.errorType = this.$t('form.required');
                return false;
            }

            if (!this.billingAddress?.city) {
                this.dataError = this.$t('form.city');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.billingAddress?.state) {
                this.dataError = this.$t('form.state');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.person?.owner) {
                this.dataError = this.$t('form.name_owner');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.getProfile.person.doc_type) {
                this.dataError = this.$t('form.document_type');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (!this.getProfile.person.cpf_cnpj) {
                this.dataError = this.$t('form.document_number');
                this.errorType = this.$t('form.required');
                return false;
            }
            if (this.validation.hasErrors) {
                this.dataError = this.$t('form.document_number');
                this.errorType = this.$t('form.invalid');
                return false;
            }

            return true;
        },
    },
    title: "Seu cadastro",
};
</script>

<style lang="scss"></style>

<template>
  <div>
    <box-message-welcome
      :message="$t('components.home.messageWelcome.exSubscriber.message')"
      :title="$t('components.home.messageWelcome.exSubscriber.greeting')"
      :emoji-codes="[215]"
    />
    <div class="mt-10 mb-10">
      <SubmitButtonDefault
        :min-height="true"
        :text="$t('components.home.messageWelcome.exSubscriber.textButton')"
        @click.native="signAction"
        @click="eventGtm('custom.exSubscriber')"
        data-cy="dashboard-subscribe_nex-btn"
      />
    </div>
  </div>
</template>

<script>
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { PLAN_SELECTOR_VIEW } from '@/router/constants';
import { mapGetters } from "vuex";

export default {
  components: {
    SubmitButtonDefault,
    BoxMessageWelcome,
  },
  computed: {
    ...mapGetters({
      eventGtm: 'eventGtm'
    })
  },
  methods: {
    signAction() {
      this.$router.push({
        name: PLAN_SELECTOR_VIEW,
      });
    },
  },
};
</script>

<style>
</style>
<template>
    <div>
        <FormulateForm v-model="profileStore" class="w-full text-left">
            <div class="flex flex-wrap">
                <FormulateInput
                    type="text"
                    name="company_name"
                    :label="$t('form.company_name')"
                    validation="required"
                    :validation-messages="{
                        required: $t('form.required_field'),
                    }"
                    :error-class="['text-red-600']"
                    icon=""
                    :outer-class="['w-full sm:w-1/2 pr-1']"
                    data-cy="profile_form-company_name-input"
                />
                <FormulateInput
                    type="text"
                    name="store_name"
                    :label="$t('form.alias')"
                    validation="required"
                    :validation-messages="{
                        required: $t('form.required_field'),
                    }"
                    :error-class="['text-red-600']"
                    icon=""
                    :outer-class="['w-full sm:w-1/2 pr-1']"
                    data-cy="profile_form-store_name-input"
                />
                <FormulateInput
                    type="text"
                    name="activity_branch"
                    :label="$t('form.branch_activity')"
                    icon="user"
                    :outer-class="['w-full']"
                    data-cy="profile_form-activity_branch-input"
                />
                <FormulateInput
                    type="text"
                    name="manager"
                    :label="$t('form.manager')"
                    icon="user"
                    :outer-class="['w-full']"
                    data-cy="profile_form-manager-input"
                />
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { STORE_MODULE } from "@/store/constants/store";
import { mapGetters } from "vuex";
export default {
    name: "FormStore",
    data() {
        return {
            profileStore: {},
            company_name: "teste",
        };
    },
    computed: {
        ...mapGetters(STORE_MODULE, ["store"]),
    },
    watch: {
        store(newValue) {
            this.profileStore = newValue?.store ?? this.profileStore;
        },
        profileStore(newValue) {
            this.$emit("storeData", newValue);
        },
    },
    mounted() {
        this.buildData();
    },
    methods: {
        buildData() {
            this.profileStore = this.store?.store;
        },
    },
};
</script>

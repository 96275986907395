<template>
  <div>
    <box-message-welcome
    :message="message"
      :title="$t('components.home.messageWelcome.subscriberOverdue.greeting')"
      :emoji-codes="[215]"
    />
    <div data-cy="dashboard-banner_bankslip-btn" class="banner-1-red">
      <p class="red">{{
        $t(
          "components.home.messageWelcome.subscriberOverdue.paymentMessageTitle"
        )
      }}</p> 
      {{
        $t(
          "components.home.messageWelcome.subscriberOverdue.paymentMessage"
        )
      }}
     <p> {{ $t(
          "components.home.messageWelcome.subscriberOverdue.secondMessage"
        ) }}
      </p>
    </div>
    <div class="button">
      <SubmitButtonDefault
        :min-height="true"
        :text="
          $t(
            'components.home.messageWelcome.subscriberOverdue.updateButton'
          )
        "
        @click.native="signAction"
        data-cy="update-bankslip"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs-ext";
import { mapGetters } from "vuex";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { PLAN_SELECTOR_VIEW } from '@/router/constants';
export default {
  components: {
    SubmitButtonDefault,
    BoxMessageWelcome,
  },
  computed: {
    ...mapGetters("store", ["store", "subscription"]),

    message() {
      const formattedRegistrationDate = dayjs(
      this.subscription?.subscription_expiration ??
        this.subscription?.license_expiration
    ).format("DD/MM/YYYY");
      return   `${this.$t(
      "components.home.messageWelcome.subscriberCreditCardPaying.message"
    )} ${formattedRegistrationDate}`;

    },
    getLineCode() {
      return this.subscription?.last_bankslip_barcode;
    },
    getPrintLink() {
      return this.subscription?.last_bankslip_url;
    },
    getAmount() {
      return this.subscription?.amount;
    },
    getDueAt() {
      return this.subscription?.last_bankslip_expiration_at;
    },
    isbankslip() {
      return this.subscription?.payment_type_code == "bank_slip" && this.subscription?.last_bankslip_barcode
    },
    getCopyPastePIX() {
      return this.subscription?.last_pix?.copyPaste
    },
    getBase64Image() {
      return this.subscription?.last_pix?.qrCodeImageBase64
    }
  },
  methods: {
    signAction() {
      this.$router.push({
        name: PLAN_SELECTOR_VIEW
      });
    },
  },
};
</script>

<style>
.red {
  color: red;
  font-weight: 700;
}
.button{
  text-align: center;
  padding-top: 20px; 
}
</style>

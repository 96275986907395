<template>
    <div>
        <box-message-welcome
            :message="message"
            :title="
                $t(
                    'components.home.messageWelcome.subscriberBankSlipPaying.greeting'
                )
            "
            :emoji-codes="[215]"
        />
        <div data-cy="dashboard-banner_bankslip-btn" class="banner-1-green">
            <p class="green">{{
        $t(
          "components.home.messageWelcome.subscriberCreditCardPaying.paymentMessageTitle"
        )
      }}</p> 
            {{ $t(
                "components.home.messageWelcome.subscriberBankSlipPaying.paymentMessage"
            ) }}
            <span>
                {{ nextbankSlip }}</span
            >
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { mapGetters } from "vuex";
import { STORE_MODULE } from "@/store/constants/store";
import { STORE } from "@/store/constants/store";
export default {
    components: {
        BoxMessageWelcome,
    },
    data() {
        return {
            message: "",
        };
    },
    computed: {
        ...mapGetters({
            store: `${STORE_MODULE}/${STORE}`,
        }),

        nextbankSlip() {
            return dayjs(this.store.subscription.license_expiration ?? this.store.subscription.subscription_expiration)
                .subtract(3, "days")
                .format("DD/MM/YYYY");
        },
    },
    mounted() {
        const formattedRegistrationDate = dayjs(this.store.confirmedAt).format(
            "DD/MM/YYYY"
        );
        this.message = `${this.$t(
            "components.home.messageWelcome.subscriberBankSlipPaying.message"
        )} ${formattedRegistrationDate}`;
    },
};
</script>

<style></style>

const Emojis = [
    128,
    // 1533,
    // 1540,
    1544,
    1545,
    1546,
    1669,
    171,
    1905,
    1937,
    1938,
    1944,
    1946,
    1947,
    1950,
    1961,
    1962,
    1965,
    2003,
    201,
    2024,
    2025,
    2026,
    2063,
    2064,
    2065,
    2081,
    2123,
    2129,
    215,
    2326,
    // 2357,
    2396,
    // 2415,
    2587,
    2589,
    509,
    54,
    57,
    62,
    626,
    628,
    63,
    641,
    731,
    // 953,
    // 959,
    983,
    // 989,
]


const baseDir = 'assets/images/emojis'

const randomEmoji = () => {
    const idx = Math.floor(Math.random() * Emojis.length)
    return require(`../${baseDir}/${Emojis[idx]}.png`)
}

const getEmoji = (codes) => {
    let code = codes
    if (typeof codes !== 'number' && typeof codes !== 'string') {
        code = codes[Math.floor(Math.random() * codes.length)]
    }
    return require(`../${baseDir}/${code}.png`)
}

export { baseDir, Emojis, getEmoji, randomEmoji }

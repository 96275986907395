var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          staticClass: "w-full text-left",
          model: {
            value: _vm.profilePhones,
            callback: function ($$v) {
              _vm.profilePhones = $$v
            },
            expression: "profilePhones",
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _c("FormulateInput", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "####################",
                    expression: "'####################'",
                  },
                ],
                attrs: {
                  type: "text",
                  label: _vm.$t("form.cell_phone"),
                  name: "lineNumber1",
                  inputmode: "numeric",
                  pattern: "[0-9]*",
                  validation: "matches:/^[0-9-\\ ]+$/",
                  "validation-messages": {
                    matches: "Apenas números e hífen",
                  },
                  "outer-class": ["lg:w-1/2 w-full sm:px-1 sm:w-1/2"],
                  "data-cy": "profile_form-mobile-input",
                },
              }),
              _c("FormulateInput", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "####################",
                    expression: "'####################'",
                  },
                ],
                attrs: {
                  type: "text",
                  label: _vm.$t("form.landline"),
                  name: "lineNumber2",
                  inputmode: "numeric",
                  pattern: "[0-9]*",
                  validation: "matches:/^[0-9-\\ ]+$/",
                  "validation-messages": {
                    matches: "Apenas números e hífen",
                  },
                  "outer-class": ["lg:w-1/2 w-full sm:px-1 sm:w-1/2"],
                  "data-cy": "profile_form-tel-input",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-message-card" }, [
    _c("div", { staticClass: "flex flex-wrap p-0 justify-between w-full" }, [
      _c(
        "div",
        {
          staticClass: "panel-left",
          attrs: { "data-cy": "cancel-banner-text" },
        },
        [
          _c("box-message-welcome", {
            attrs: { title: _vm.$t("cancel_plan.title"), "emoji-codes": [721] },
          }),
          _c("p", [_vm._v(_vm._s(_vm.$t("cancel_plan.farewellMessage")))]),
          _c("br"),
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("cancel_plan.buttonSolicitCancel")) + " "
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("cancel_plan.informationsToCancel")) + " "
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("SubmitButtonDefault", {
                attrs: {
                  text: _vm.$t("initChat"),
                  "show-icon": false,
                  "data-cy": "cancel-chat-btn",
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.openChat()
                    _vm.eventGtm("custom.clickCancelButton")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "panel-right",
          attrs: { "data-cy": "cancel-panel-text" },
        },
        [
          _c("h1", { staticClass: "text-lg font-semibold mb-10" }, [
            _vm._v(" " + _vm._s(_vm.$t("cancel_plan.benefits")) + " "),
          ]),
          _vm._l(_vm.infoText, function (info, i) {
            return _c(
              "p",
              { key: i, staticClass: "mb-2" },
              [
                _c("IconTimes", {
                  staticClass: "h-5 inline",
                  attrs: { "icon-style": "solid", color: "red" },
                }),
                _vm._v(" " + _vm._s(info.text) + " "),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          staticClass: "w-full text-left",
          model: {
            value: _vm.profileStore,
            callback: function ($$v) {
              _vm.profileStore = $$v
            },
            expression: "profileStore",
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _c("FormulateInput", {
                attrs: {
                  type: "text",
                  name: "company_name",
                  label: _vm.$t("form.company_name"),
                  validation: "required",
                  "validation-messages": {
                    required: _vm.$t("form.required_field"),
                  },
                  "error-class": ["text-red-600"],
                  icon: "",
                  "outer-class": ["w-full sm:w-1/2 pr-1"],
                  "data-cy": "profile_form-company_name-input",
                },
              }),
              _c("FormulateInput", {
                attrs: {
                  type: "text",
                  name: "store_name",
                  label: _vm.$t("form.alias"),
                  validation: "required",
                  "validation-messages": {
                    required: _vm.$t("form.required_field"),
                  },
                  "error-class": ["text-red-600"],
                  icon: "",
                  "outer-class": ["w-full sm:w-1/2 pr-1"],
                  "data-cy": "profile_form-store_name-input",
                },
              }),
              _c("FormulateInput", {
                attrs: {
                  type: "text",
                  name: "activity_branch",
                  label: _vm.$t("form.branch_activity"),
                  icon: "user",
                  "outer-class": ["w-full"],
                  "data-cy": "profile_form-activity_branch-input",
                },
              }),
              _c("FormulateInput", {
                attrs: {
                  type: "text",
                  name: "manager",
                  label: _vm.$t("form.manager"),
                  icon: "user",
                  "outer-class": ["w-full"],
                  "data-cy": "profile_form-manager-input",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="welcome-painel-left">
    <subscriber-bank-slip-paying v-if="isSubscriberBankslipPaying" />
    <subscriber-credit-card-paying v-else-if="isSubscriberCreditCardPaying" />
    <subscriber-bank-slip-open v-else-if="isSubscriberBankslipOpen" />
    <subscriber-overdue v-else-if="isSubscriberOverdue || isExSubscriber" />
    <not-subscriber v-else />
    <div v-if="!isFree" class="mt-5">
      <router-link
        class="link text-nexteal-700"
        :to="{ name: route }"
        @click.native="eventGtm('custom.changePaymentMethod')"
      >
        {{ $t("components.home.messageWelcome.changePaymentMethod") }}
      </router-link>
    </div>
    
  </div>
</template>

<script>
import { CHANGE_PAYMENT_METHOD_VIEW, PLAN_SELECTOR_VIEW } from "@/router/constants";
import { mapGetters } from "vuex";
import {
  NotSubscriber,
  SubscriberBankSlipOpen,
  SubscriberBankSlipPaying,
  SubscriberCreditCardPaying,
  SubscriberOverdue,
} from "../../components/WelcomePages/index";
export default {
  name: "MessageWelcome",
  components: {
    SubscriberBankSlipPaying,
    NotSubscriber,
    SubscriberCreditCardPaying,
    SubscriberBankSlipOpen,
    SubscriberOverdue,
  },
  data() {
    return {
      routeChangePaymentMethod: null
    };
  },
  computed: {
    ...mapGetters("store", ["paymentStatusUser", "isNonDefaulter"]),
    ...mapGetters({
      eventGtm: 'eventGtm',
    }),
    isSubscriberPending() {
      return localStorage.getItem('pending') == 'true';
    },
    isNotSubscriber() {
      return this.paymentStatusUser == "not_subscriber";
    },
    isSubscriberBankslipPaying() {
      return this.paymentStatusUser == "subscriber_bankslip_paying";
    },
    isSubscriberCreditCardPaying() {
      return this.paymentStatusUser == "subscriber_credit_card_paying";
    },
    isSubscriberBankslipOpen() {
      return this.paymentStatusUser?.includes?.("subscriber_bankslip_open");
    },
    isSubscriberOverdue() {
      return this.paymentStatusUser == "subscriber_overdue";
    },
    isExSubscriber() {
      return this.paymentStatusUser == "ex_subscriber";
    },
    isAvailableToChangePaymentMethod() {
      return this.isNonDefaulter;
    },
    isPreLib(){
      return this.paymentStatusUser == "subscriber_bankslip_open_pre_lib";
    },
    isFree() {
            return (
                this.paymentStatusUser === "ex_subscriber" ||
                this.paymentStatusUser === "not_subscriber"
            );
        },
    route(){
      return this.isNonDefaulter? CHANGE_PAYMENT_METHOD_VIEW  : PLAN_SELECTOR_VIEW 
    }
  },
  mounted() { 
    
    if (this.isSubscriberPending) {
      setTimeout(() => {
          localStorage.setItem ('pending', false); 
          location.reload()
      }, 5 * 60 * 1000);
    }
  }
};
</script>

<style>
.welcome-painel-left {
  @apply w-2/3 flex text-left pr-5 block;
}
@media (max-width: 640px) {
  .welcome-painel-left {
    @apply w-full text-center justify-start block;
  }
  .box-message-text {
    @apply p-0;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("box-message-welcome", {
        attrs: {
          message: _vm.message,
          title: _vm.$t(
            "components.home.messageWelcome.subscriberBankSlipPaying.greeting"
          ),
          "emoji-codes": [215],
        },
      }),
      _c(
        "div",
        {
          staticClass: "banner-1-green",
          attrs: { "data-cy": "dashboard-banner_bankslip-btn" },
        },
        [
          _c("p", { staticClass: "green" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberCreditCardPaying.paymentMessageTitle"
                )
              )
            ),
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberBankSlipPaying.paymentMessage"
                )
              ) +
              " "
          ),
          _c("span", [_vm._v(" " + _vm._s(_vm.nextbankSlip))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
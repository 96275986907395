<template>
  <div>
    <FormulateForm v-model="profilePhones" class="w-full text-left">
      <div class="flex flex-wrap">
        <FormulateInput
          v-mask="'####################'"
          type="text"
          :label="$t('form.cell_phone')"
          name="lineNumber1"
          inputmode="numeric"
          pattern="[0-9]*"
          validation="matches:/^[0-9-\ ]+$/"
          :validation-messages="{
            matches: 'Apenas números e hífen',
          }"
          :outer-class="['lg:w-1/2 w-full sm:px-1 sm:w-1/2']"
          data-cy="profile_form-mobile-input"
        />
        <FormulateInput
          v-mask="'####################'"
          type="text"
          :label="$t('form.landline')"
          name="lineNumber2"
          inputmode="numeric"
          pattern="[0-9]*"
          validation="matches:/^[0-9-\ ]+$/"
          :validation-messages="{
            matches: 'Apenas números e hífen',
          }"
          :outer-class="['lg:w-1/2 w-full sm:px-1 sm:w-1/2']"
          data-cy="profile_form-tel-input"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { STORE_MODULE } from "@/store/constants/store";

export default {
  name: "FormPhone",
  directives: { mask },
  data() {
    return {
      profilePhones: {},
      mask: [
        {
          mask: "(00) 0000-0000",
        },
        {
          mask: "(00) 90000-0000",
        },
      ],
      operators: {
        vivo: "Vivo",
        claro: "Claro",
        tim: "TIM",
        oi: "Oi",
        nextel: "Nextel",
        algar: "Algar",
        sercomtel: "Sercomtel",
        porto_seguro: "Porto Seguro",
        datora: "Datora",
        terapar: "Terapar",
      },
      line_type: {
        land_line: "Fixo",
        mobile: "Móvel",
      },
    };
  },
  computed: {
    ...mapGetters(STORE_MODULE, ["phones"]),
  },
  watch: {
    profilePhones(newValue) {
      this.$emit("phoneData", newValue);
    },
  },
  mounted() {
    this.profilePhones = this.phones;
  },
};
</script>

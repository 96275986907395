var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          staticClass: "text-left",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.update.apply(null, arguments)
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              [
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "company_name",
                    label: _vm.$t("form.name_owner"),
                    validation: "required",
                    "validation-messages": {
                      required: _vm.$t("form.required_field"),
                    },
                    "error-class": ["text-red-600"],
                    icon: "",
                    "outer-class": ["w-full"],
                    "data-cy": "profile_form-owner-input",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.eventGtm("custom.editOwnerName")
                    },
                  },
                  model: {
                    value: _vm.profilePerson.owner,
                    callback: function ($$v) {
                      _vm.$set(_vm.profilePerson, "owner", $$v)
                    },
                    expression: "profilePerson.owner",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("box-message-welcome", {
        attrs: {
          title: _vm.$t(
            "components.home.messageWelcome.subscriberBankSlipOpen.greeting"
          ),
          message: _vm.$t(
            "components.home.messageWelcome.subscriberBankSlipOpen.message"
          ),
          "emoji-codes": [215],
        },
      }),
      _c(
        "div",
        {
          staticClass: "banner-1-yellow",
          attrs: { "data-cy": "dashboard-banner_bankslip-btn" },
        },
        [
          _c("p", { staticClass: "yellow" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberBankSlipOpen.paymentMessageTitle"
                )
              )
            ),
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberBankSlipOpen.paymentMessage"
                )
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "button" },
        [
          _c("SubmitButtonDefault", {
            attrs: {
              "min-height": true,
              text: _vm.$t(
                "components.home.messageWelcome.subscriberBankSlipOpen.pdfButton"
              ),
              "data-cy": "pdf-button",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.signAction.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
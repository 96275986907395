<template>
  <div>
    <box-message-welcome
      :title="
        $t('components.home.messageWelcome.subscriberBankSlipOpen.greeting')
      "
      :message="
        $t('components.home.messageWelcome.subscriberBankSlipOpen.message')
      "
      :emoji-codes="[215]"
    />
    <div data-cy="dashboard-banner_bankslip-btn" class="banner-1-yellow">
      <p class="yellow">{{
        $t(
          "components.home.messageWelcome.subscriberBankSlipOpen.paymentMessageTitle"
        )
      }}</p> 
      {{
        $t(
          "components.home.messageWelcome.subscriberBankSlipOpen.paymentMessage"
        )
      }}
    </div>
    <div class="button">
      <SubmitButtonDefault
        :min-height="true"
        :text="
          $t(
            'components.home.messageWelcome.subscriberBankSlipOpen.pdfButton'
          )
        "
        @click.native="signAction"
        data-cy="pdf-button"
      />
    </div>
  </div>
</template>

<script>
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { mapGetters } from "vuex";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
export default {
  components: {
    BoxMessageWelcome,
    SubmitButtonDefault
  },
  computed: {
    ...mapGetters("store", ["store", "subscription"]),

    getLineCode() {
      return this.subscription?.last_bankslip_barcode;
    },
    getPrintLink() {
      return this.subscription?.last_bankslip_url;
    },
    getAmount() {
      return (this.subscription?.last_bankslip_barcode.slice(-6))/100;
    },
    getDueAt() {
      return this.subscription?.last_bankslip_expiration_at;
    },
    getCopyPastePIX() {
      return this.subscription?.last_pix?.copyPaste
    },
    getBase64Image() {
      return this.subscription?.last_pix?.qrCodeImageBase64
    }
  },
  methods: {
    signAction() {
      const url = this.subscription?.last_bankslip_url + '&format=pdf'
      window.open(url, '_blank');
    },
  },

};
</script>

<style>
.yellow {
  color: #fddb3a;
  font-weight: 700;
}
</style>

<template>
    <div class="m-0 p-0">
        <div class="home">
            <message-welcome />
            <user-data />
        </div>
        <div
            class="text-sm text-gray-400 hover:underline text-right w-full"
            data-cy="dashboard-cancel-div"
        >
            <router-link v-if="!isNotSubscriber"
                :to="{ name: routeCancel }"
                @click.native="eventGtm('custom.linkCancelPlan')"
            >
                {{ $t("components.home.cancelPlan") }}
            </router-link>
        </div>
    </div>
</template>

<script>
import MessageWelcome from "@/components/Home/MessageWelcome.vue";
import UserData from "@/components/Home/UserData.vue";
import { CANCEL_PLAN_VIEW } from "@/router/constants";
import { mapGetters } from "vuex";

export default {
    name: "HomeView",
    components: { MessageWelcome, UserData },
    data() {
        return {
            routeCancel: CANCEL_PLAN_VIEW,
        };
    },
    computed: {
        ...mapGetters("store", ["paymentStatusUser"]),
        ...mapGetters({
            eventGtm: 'eventGtm'
        }),
        isNotSubscriber() {
            return this.paymentStatusUser == "not_subscriber";
        },
    }
};
</script>
<style scoped>
.home {
    @apply p-5 inline-flex w-full;
}
@media (max-width: 640px) {
    .home {
        @apply block;
    }
}
</style>

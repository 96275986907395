var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("box-message-welcome", {
        attrs: {
          message: _vm.$t(
            "components.home.messageWelcome.exSubscriber.message"
          ),
          title: _vm.$t("components.home.messageWelcome.exSubscriber.greeting"),
          "emoji-codes": [215],
        },
      }),
      _c(
        "div",
        { staticClass: "mt-10 mb-10" },
        [
          _c("SubmitButtonDefault", {
            attrs: {
              "min-height": true,
              text: _vm.$t(
                "components.home.messageWelcome.exSubscriber.textButton"
              ),
              "data-cy": "dashboard-subscribe_nex-btn",
            },
            on: {
              click: function ($event) {
                return _vm.eventGtm("custom.exSubscriber")
              },
            },
            nativeOn: {
              click: function ($event) {
                return _vm.signAction.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
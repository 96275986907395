<template>
    <div>
        <box-message-welcome
            :message="
                $t('components.home.messageWelcome.notSubscriber.message')
            "
            :title="$t('components.home.messageWelcome.notSubscriber.greeting')"
            :emoji-codes="[215]"
        />
        <ul class="my-6">
            <li
                v-for="(motivation, index) in motivations"
                :key="index"
                class="my-2"
            >
                <CheckIcon class="w-5 inline" style="fill: #0099f9" />
                {{ motivation }}
            </li>
        </ul>
        <div class="mt-10 mb-10">
            <SubmitButtonDefault
                :min-height="true"
                :text="
                    $t(
                        'components.home.messageWelcome.notSubscriber.textButton'
                    )
                "
                @click.native="signAction(); eventGtm('custom.newSubscriber');"
                data-cy="dashboard-subscribe_nex-btn"
            />
        </div>
    </div>
</template>

<script>
import CheckIcon from "@/components/Icons/IconCheck.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import BoxMessageWelcome from "../Home/BoxMessageWelcome.vue";
import { PLAN_SELECTOR_VIEW } from "@/router/constants";
import { mapGetters } from "vuex";
export default {
    components: {
        CheckIcon,
        SubmitButtonDefault,
        BoxMessageWelcome,
    },
    computed: {
        ...mapGetters({
            eventGtm: 'eventGtm'
        }),
        motivations() {
            return [
                this.$t(
                    "components.home.messageWelcome.notSubscriber.firstMotivation"
                ),
                this.$t(
                    "components.home.messageWelcome.notSubscriber.secondMotivation"
                ),
                this.$t(
                    "components.home.messageWelcome.notSubscriber.thirdMotivation"
                ),
                this.$t(
                    "components.home.messageWelcome.notSubscriber.fourthMotivation"
                ),
                this.$t(
                    "components.home.messageWelcome.notSubscriber.fifthMotivation"
                ),
            ];
        },
    },
    methods: {
        signAction() {
            this.$router.push({
                name: PLAN_SELECTOR_VIEW,
            });
        },
    },
};
</script>

<style></style>

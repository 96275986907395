var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("box-message-welcome", {
        attrs: {
          message: _vm.$t(
            "components.home.messageWelcome.notSubscriber.message"
          ),
          title: _vm.$t(
            "components.home.messageWelcome.notSubscriber.greeting"
          ),
          "emoji-codes": [215],
        },
      }),
      _c(
        "ul",
        { staticClass: "my-6" },
        _vm._l(_vm.motivations, function (motivation, index) {
          return _c(
            "li",
            { key: index, staticClass: "my-2" },
            [
              _c("CheckIcon", {
                staticClass: "w-5 inline",
                staticStyle: { fill: "#0099f9" },
              }),
              _vm._v(" " + _vm._s(motivation) + " "),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "mt-10 mb-10" },
        [
          _c("SubmitButtonDefault", {
            attrs: {
              "min-height": true,
              text: _vm.$t(
                "components.home.messageWelcome.notSubscriber.textButton"
              ),
              "data-cy": "dashboard-subscribe_nex-btn",
            },
            nativeOn: {
              click: function ($event) {
                _vm.signAction()
                _vm.eventGtm("custom.newSubscriber")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="w-full">
    <img :src="emoji" class="mb-5 w-24" />
    <h1 class="text-3xl font-bold" v-html="getTitle" />
    <div class="box-message-text">
      <p v-html="message" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { randomEmoji, getEmoji } from "../../constants/emojis";

export default {
  props: {
    emojiCodes: {
      type: Array,
      default: new Array(),
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    message: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      titleView: this.getTitle ?? "",
    };
  },
  computed: {
    ...mapGetters("store", ["firstName"]),
    getTitle() {
      return `${this.title}, ${this.firstName ?? ""}!`;
    },
    emoji() {
      if (this.emojiCodes.length > 0) {
        return getEmoji(this.emojiCodes);
      }
      return randomEmoji();
    },
    getMessage() {
      return this.message;
    },
  },
};
</script>

<style lang="css">
.check {
  @apply text-nexteal-500;
}
.box-message-text {
  @apply mt-6 text-2xl pr-20 w-full;
}
</style>

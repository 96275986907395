import router from '@/router/index'
export default [
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item1') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item2') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item3') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item4') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item5') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item6') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item7') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item8') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item9') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item10') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item11') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item12') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item13') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item14') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item15') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item16') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item17') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item18') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item19') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item20') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item21') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item22') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item23') },
  { icon: 'check', color: null, plan: 7, free: 0, pro: 0, premium: 0, fiscal: 1, text: router.app.$t('cancel_plan.item24') },
  { icon: 'check', color: null, plan: 7, free: 0, pro: 0, premium: 0, fiscal: 1, text: router.app.$t('cancel_plan.item25') },
  { icon: 'check', color: null, plan: 7, free: 0, pro: 0, premium: 0, fiscal: 1, text: router.app.$t('cancel_plan.item26') },
  { icon: 'check', color: null, plan: 7, free: 0, pro: 0, premium: 0, fiscal: 1, text: router.app.$t('cancel_plan.item27') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item28') },
  { icon: 'check', color: null, plan: 0, free: 1, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item29') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item30') },
  { icon: 'check', color: null, plan: 6, free: 0, pro: 1, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item31') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item32') },
  { icon: 'check', color: null, plan: 5, free: 0, pro: 0, premium: 1, fiscal: 1, text: router.app.$t('cancel_plan.item33') },
  { icon: 'check', color: null, plan: 7, free: 0, pro: 0, premium: 0, fiscal: 1, text: router.app.$t('cancel_plan.item34') },
]

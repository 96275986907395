var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("box-message-welcome", {
        attrs: {
          message: _vm.message,
          title: _vm.$t(
            "components.home.messageWelcome.subscriberOverdue.greeting"
          ),
          "emoji-codes": [215],
        },
      }),
      _c(
        "div",
        {
          staticClass: "banner-1-red",
          attrs: { "data-cy": "dashboard-banner_bankslip-btn" },
        },
        [
          _c("p", { staticClass: "red" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberOverdue.paymentMessageTitle"
                )
              )
            ),
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.home.messageWelcome.subscriberOverdue.paymentMessage"
                )
              ) +
              " "
          ),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "components.home.messageWelcome.subscriberOverdue.secondMessage"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "button" },
        [
          _c("SubmitButtonDefault", {
            attrs: {
              "min-height": true,
              text: _vm.$t(
                "components.home.messageWelcome.subscriberOverdue.updateButton"
              ),
              "data-cy": "update-bankslip",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.signAction.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "welcome-panel-right" }, [
    _vm.isDesktop
      ? _c(
          "div",
          {
            staticClass:
              "inline-flex top-5 right-5 cursor-pointer opacity-25 hover:opacity-50 icon",
            on: { click: _vm.alertClose },
          },
          [_c("div", {}, [_c("IconExit", { attrs: { color: "black" } })], 1)]
        )
      : _vm._e(),
    _c("div", { staticClass: "h-full" }, [
      _c("div", [
        _c("div", { staticClass: "grid grid-cols-2 gap-x-6" }, [
          _c("div", { staticClass: "mb-6 text-md font-extrabold" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("components.home.userData.title")) + " "
            ),
          ]),
        ]),
        _c("p", { staticClass: "item" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("components.home.userData.account")))]),
          _c("code", { attrs: { "data-cy": "dashboard-account-text" } }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.account) } }),
          ]),
        ]),
        _c("p", { staticClass: "item" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("components.home.userData.plan")))]),
          _c("code", { attrs: { "data-cy": "dashboard-plan-text" } }, [
            _vm.isSubscriberPaying || _vm.isTrial
              ? _c("span", [
                  _vm._v("Premium "),
                  _vm.isTrial ? _c("span", [_vm._v("- Trial")]) : _vm._e(),
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.home.userData.free"))),
                ]),
          ]),
        ]),
        _vm.isTrial ||
        _vm.isNonDefaulter ||
        (_vm.hasBankSlipOpen && _vm.subscription?.subscription_expiration)
          ? _c("p", { staticClass: "item" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("components.home.userData.validity"))),
              ]),
              _c(
                "code",
                { attrs: { "data-cy": "dashboard-expiration-text" } },
                [_c("span", { domProps: { innerHTML: _vm._s(_vm.expiredAt) } })]
              ),
            ])
          : _vm._e(),
        !_vm.isTrial && !_vm.isFree && _vm.paymentMethod
          ? _c("p", { staticClass: "item" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("components.home.userData.paymentMethod"))
                ),
              ]),
              _c(
                "code",
                { attrs: { "data-cy": "dashboard-payment_method-text" } },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.paymentMethod) },
                  }),
                  _vm._v(" / "),
                  _vm.recurrence
                    ? _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.recurrence) },
                      })
                    : _c("span", {
                        domProps: { innerHTML: _vm._s("&hellip;") },
                      }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-0 p-0" }, [
    _c(
      "div",
      { staticClass: "home" },
      [_c("message-welcome"), _c("user-data")],
      1
    ),
    _c(
      "div",
      {
        staticClass: "text-sm text-gray-400 hover:underline text-right w-full",
        attrs: { "data-cy": "dashboard-cancel-div" },
      },
      [
        !_vm.isNotSubscriber
          ? _c(
              "router-link",
              {
                attrs: { to: { name: _vm.routeCancel } },
                nativeOn: {
                  click: function ($event) {
                    return _vm.eventGtm("custom.linkCancelPlan")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("components.home.cancelPlan")) + " ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "welcome-painel-left" },
    [
      _vm.isSubscriberBankslipPaying
        ? _c("subscriber-bank-slip-paying")
        : _vm.isSubscriberCreditCardPaying
        ? _c("subscriber-credit-card-paying")
        : _vm.isSubscriberBankslipOpen
        ? _c("subscriber-bank-slip-open")
        : _vm.isSubscriberOverdue || _vm.isExSubscriber
        ? _c("subscriber-overdue")
        : _c("not-subscriber"),
      !_vm.isFree
        ? _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "router-link",
                {
                  staticClass: "link text-nexteal-700",
                  attrs: { to: { name: _vm.route } },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.eventGtm("custom.changePaymentMethod")
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.home.messageWelcome.changePaymentMethod"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        
        <FormulateForm class="text-left" @submit.prevent="update">
                <div >
                    <div>
                        <FormulateInput
                            v-model="profilePerson.owner"
                            type="text"
                            name="company_name"
                            :label="$t('form.name_owner')"
                            validation="required"
                            :validation-messages="{
                                required: $t('form.required_field'),
                            }"
                            :error-class="['text-red-600']"
                            icon=""
                            :outer-class="['w-full']"
                            data-cy="profile_form-owner-input"
                            @click="eventGtm('custom.editOwnerName')"
                        />
                    </div>
                </div>
            </FormulateForm>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { STORE_MODULE, STORE } from "@/store/constants/store";

export default {
    name: "FormPerson",
    directives: { mask },
    data() {
        return {
            profilePerson: {},
            tipoDocList: {
                CUIT:"CUIT",
                CUIL:"CUIL",
                CDI:"CDI",
                DNI:"DNI",
            },
            profile:{
                owner: "",
                document: "",
                documentType: ""
            },
            documents: ""
        };
    },
    computed: {
        ...mapGetters(STORE_MODULE, ["person"]),
        ...mapGetters({
            getProfile: `${STORE_MODULE}/${STORE}`,
            eventGtm: 'eventGtm'
        }),
    },
    watch: {
        profilePerson(newValue) {
            this.$emit("personData", newValue);
        },
    },
    mounted() {
        this.buildData();
        this.profile.owner = this.profilePerson.owner;
    },
    methods: {
        buildData() {
            this.profilePerson = this.person;
        },
    },
};
</script>
